import { useUrlState } from '@village/tools';
import { Button, Spinner } from '@village/ui';
import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    MaxFileSize,
    MessageLengthLimit,
    MessageLengthWarning,
    SendThrottleInterval,
    SubjectLengthLimit,
    debounceDelay,
} from './constants';
import * as Styled from './styles';
import { AttachmentFailModal } from 'components/attachment-fail-modal';
import { AttachmentInfo } from 'components/attachment-info';
import { AttachmentModal } from 'components/attachment-modal';
import { CardButton } from 'components/card-button';
import { CenteredSpinner } from 'components/centered-spinner';
import { Icon } from 'components/icon';
import { MessageTextArea, appPrefTitle } from 'components/message-text-area';
import type { ValidateHandle } from 'components/message-text-area';
import { useCreateNewMessage } from 'data/hooks/use-create-new-message-query';
import { useIndividualTestResult } from 'data/hooks/use-individual-test-result';
import { useMessageThreadAttachment } from 'data/hooks/use-message-thread-attachment-mutation';
import type { SuccessReplyResponse } from 'data/hooks/use-message-thread-attachment-mutation';
import { useMessageThread } from 'data/hooks/use-message-thread-query';
import { useProviders } from 'data/hooks/use-providers';
import { useFeatureFlags, useThrottle } from 'hooks';
import { useDebounce } from 'hooks/use-debounce';
import { useMessaging } from 'hooks/use-messaging';
import { useNativeControls } from 'hooks/use-native-controls';
import { useNativeIpc } from 'hooks/use-native-ipc';
import { useNavHeader } from 'hooks/use-nav';
import { Countly } from 'modules/countly';
import { sendToNative } from 'modules/ipc';
// import type { DrawerIPCBody, MessageBase, NativePopupState } from 'modules/ipc/types';
import type { MessageBase, NativePopupState } from 'modules/ipc/types';
import type { MessageTypeKeys, TestResultType } from 'types';
import type { CountlySegmentation } from 'types/countly';
import type { Provider } from 'types/provider';
import { getProviderDisplayName } from 'utils/provider';

// const openSuccessPopup = (): void =>
//     sendToNative('openNativeDrawer', 'health-records', {
//         bodyText:
//             '<div style="padding: 0 1rem;font-weight:400!important; font-size:16px!important;line-height: 24px;font-family=\'SF Pro Text\',Roboto;color:#414955">Your request for a medication renewal is currently being processed. Requests take between <b>1 to 3</b> business days. <br><br>Please note, requests for certain medications may require an office visit.</div>',
//         buttonText: 'Okay',
//         icon: 'checkmark',
//         titleText: 'Your renewal request was sent!',
//     } as DrawerIPCBody);

const ComposeMessage: FC = () => {
    const {
        messagingState: {
            messageType,
            messageSubject: subject,
            messageText: message,
            defaultSubject,
            visitOption,
            provider: providerObject,
            providerid: testProviderId,
            medicationName,
            testResult,
            attachments,
        },
        setMessagingFields,
        resetMessagingState,
    } = useMessaging();

    // if present, redirect the user back after sending the message
    const [redirectToRouter] = useUrlState('redirect-to');
    const redirectTo = redirectToRouter != null;
    const shouldQueryTestResult = redirectTo; //  here for clarity
    const messageTextDebounced = useDebounce<string>(message, debounceDelay);
    const navigate = useNavigate();
    const [provider, setProvider] = useState(testProviderId ? null : providerObject);
    const { refetch: fetchProviders, isFetching: isLoadingProviders } = useProviders(false);
    const { hasFeature } = useFeatureFlags();
    const { refetch: fetchTestResultThread, isFetching: isLoadingMessageThreads } = useMessageThread(
        Number(testResult?.testResultId),
        testResult?.type,
        false
    );
    const [suspendControlsIPC, setSuspendControlsIPC] = useState(testResult?.testResultId !== undefined && shouldQueryTestResult);
    const isAttachmentsEnabled = hasFeature('attachments');
    const [isAttachmentModalOpen, setIsAttachmentModalOpen] = useState<boolean>(false);
    const [isFailAttachmentModalOpen, setIsFailAttachmentModalOpen] = useState<boolean>(false);
    const totalAttachments = Object.keys(attachments ?? {}).length;
    const hasAttachments = totalAttachments > 0;
    const attachmentsDoneUploading = useRef<number>(0);
    const messageTextAreaRef = useRef<ValidateHandle>(null);
    const { setNavHeaderState } = useNavHeader();
    const [subjectFromUrl] = useUrlState('subject');
    const [messageTypeFromUrl] = useUrlState('messageType');
    const [providerNameFromUrl] = useUrlState('providerName');
    const [providerImageFromUrl] = useUrlState('providerImg');
    const [providerIdFromUrl] = useUrlState('providerId');
    const [departmentIdFromUrl] = useUrlState('departmentId');
    const hasProviderFromUrl = providerNameFromUrl && Number(providerIdFromUrl) && messageTypeFromUrl;
    const [parentSource] = useUrlState('parentSource');
    const [testResultId] = useUrlState('testResultId');
    const [testResultType] = useUrlState('testResultType');

    // This query was added to get testResultData from passing the testResultId from the RN screen.
    // the query will automatically be disabled if testResultType is null.
    const { testResult: testResultData, isFetching: isLoadingTestResult } = useIndividualTestResult({
        testResultId: Number(testResultId),
        testResultType: (testResultType as TestResultType) ?? null,
    });

    // if provider is available from the url params, set it as the provider
    useEffect(() => {
        if (hasProviderFromUrl) {
            setProvider({
                department_ids: [Number(departmentIdFromUrl)],
                displayname: providerNameFromUrl,
                entitytype: null,
                firstname: '',
                lastname: '',
                npi: null,
                provider_image_url: providerImageFromUrl,
                providerid: Number(providerIdFromUrl),
                providertype: null,
                providertypeid: null,
                specialty: null,
            });
        }
        if (subjectFromUrl || messageTypeFromUrl || hasProviderFromUrl || departmentIdFromUrl) {
            setMessagingFields({
                messageInitiatedFrom: 'native',
            });
        }
        if (subjectFromUrl) {
            setMessagingFields({
                messageSubject: subjectFromUrl,
            });
        }
        if (messageTypeFromUrl) {
            setMessagingFields({
                messageType: messageTypeFromUrl as MessageTypeKeys,
            });
        }
    }, [
        hasProviderFromUrl,
        messageTypeFromUrl,
        providerIdFromUrl,
        providerImageFromUrl,
        providerNameFromUrl,
        setMessagingFields,
        subjectFromUrl,
        departmentIdFromUrl,
    ]);

    useEffect(() => {
        if (testResultData) {
            setMessagingFields({
                messageSubject: `${testResultData.description ?? ''} ${testResultId ?? ''}`.trim(),
                messageType: testResultData.messageTypeKey,
                provider: testResultData.provider,
                testResult: testResultData,
            });
            setProvider(testResultData.provider);
        }
    }, [setMessagingFields, testResultData, testResultId]);

    // if provider object is set, use that, if not try and match the providerid
    // with the data from the useProviders hook.
    useEffect(() => {
        async function fetchData(): Promise<void> {
            if (testProviderId === undefined) return;
            const { data: providerData } = await fetchProviders();
            const matchedProvider = providerData?.results.find(({ providerid }) => providerid === testProviderId);
            if (matchedProvider) {
                setProvider(matchedProvider);
            } else {
                setProvider(providerObject);
            }
        }
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        fetchData();
    }, [fetchProviders, providerObject, testProviderId]);

    // If the user has been redirected to compose message page from an outside
    // page, we need to check if there's an existing thread associated with the
    // test result.
    // We don't want to make this check for users coming through the standard
    // messaging flow (needles load times) because there is no way for them to
    // get in this situation.
    useEffect(() => {
        async function fetchData(): Promise<void> {
            if (testResult === undefined || !shouldQueryTestResult) return;
            const { data: messageThreadData } = await fetchTestResultThread();
            if (messageThreadData?.results[0]?.associated_message_thread_id != null) {
                if (redirectToRouter === 'native') {
                    navigate(`/message-reply/${testResult.testResultId}/inbox/${testResult.type}`, { replace: true });
                } else {
                    navigate(`/message/${testResult.testResultId}/inbox/${testResult.type}`, { replace: true });
                }
            } else {
                setSuspendControlsIPC(false);
            }
        }
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        fetchData();
    }, [
        fetchTestResultThread,
        navigate,
        redirectToRouter,
        shouldQueryTestResult,
        testResult,
        testResult?.testResultId,
        testResult?.type,
    ]);

    const setSubject = useCallback(
        (newSubject) => {
            setMessagingFields({
                messageSubject: newSubject,
            });
        },
        [setMessagingFields]
    );

    const setMessage = useCallback(
        (newMessageText) => {
            setMessagingFields({
                messageText: newMessageText,
            });
        },
        [setMessagingFields]
    );

    useEffect(() => {
        if (redirectToRouter) {
            setMessagingFields({
                messageInitiatedFrom: redirectToRouter,
            });
        }
    }, [redirectToRouter, setMessagingFields]);

    const openFailAttachmentModal = useCallback(() => {
        setIsFailAttachmentModalOpen(true);
    }, [setIsFailAttachmentModalOpen]);

    const navigateToMessaging = useCallback(() => {
        sendToNative('onClickSendMessage', 'messaging', {
            type: 'Success',
        });
        resetMessagingState();
        navigate(redirectToRouter ?? '/messaging', { replace: !!redirectToRouter });
    }, [navigate, redirectToRouter, resetMessagingState]);

    // eslint-disable-next-line functional/prefer-readonly-type
    const navigateToThreadOnErrorParams = useRef<{ failedAttachments: File[]; messageThreadId: number }>({
        failedAttachments: [],
        messageThreadId: 0,
    });

    const navigateToThreadOnError = useCallback(() => {
        const { failedAttachments, messageThreadId } = navigateToThreadOnErrorParams.current;
        const source = testResult ? testResult.type : 'provider';
        navigate(`/message/${messageThreadId}/inbox/${source}`, { replace: true, state: failedAttachments });
    }, [navigate, testResult]);

    const { isLoading: createNewMessageIsLoading, mutate: createNewMessageMutate } = useCreateNewMessage({
        onError: () => {
            sendToNative('onClickSendMessage', 'messaging', {
                type: 'Error',
            });
        },
        onSuccess: async ({ message_thread_id: messageThreadId }) => {
            // eslint-disable-next-line no-constant-condition
            if (hasAttachments && false) {
                const attachmentsObjectList = Object.values(attachments);
                const promises = attachmentsObjectList.map(async (file) => {
                    const formData = new FormData();
                    formData.append('file', file);
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    return attachmentMutateAsync({ formData, messageThreadId });
                });
                const submitAttachments = async (): Promise<readonly PromiseSettledResult<SuccessReplyResponse>[]> =>
                    Promise.allSettled(promises);
                const results = await submitAttachments();
                const isSuccess = !results.some((r) => r.status === 'rejected');

                if (isSuccess) {
                    navigateToMessaging();
                } else {
                    const failedAttachments = attachmentsObjectList.filter((_, index) => results[index].status === 'rejected');
                    // eslint-disable-next-line functional/immutable-data
                    navigateToThreadOnErrorParams.current.failedAttachments = failedAttachments;
                    // eslint-disable-next-line functional/immutable-data
                    navigateToThreadOnErrorParams.current.messageThreadId = messageThreadId;
                    openFailAttachmentModal();
                }
            } else {
                navigateToMessaging();
            }
        },
    });

    const { mutateAsync: attachmentMutateAsync } = useMessageThreadAttachment({
        onUploadProgress: ({ bytes, loaded }) => {
            if (bytes === loaded) {
                // eslint-disable-next-line functional/immutable-data
                attachmentsDoneUploading.current += 1;
                sendToNative('showPopup', 'messaging', {
                    buttonCancel: {
                        text: 'Cancel',
                        type: 'primary',
                    },
                    text: `${attachmentsDoneUploading.current}/${totalAttachments}`,
                    title: 'Uploading...',
                } as NativePopupState);
            }
        },
    });

    // we use it to track how the patient ended up on this page and compose a message
    const initializationSource = useUrlState('source');

    useEffect(
        () => () => {
            sendToNative('showDiscardPopup', 'messaging', {
                value: false, // Ensure native does not show popup when a reply is successful!
            });
        },
        []
    );

    const providerImage = useMemo(() => {
        if (isLoadingProviders) {
            return (
                <Styled.SpinnerContainer>
                    <Spinner />
                </Styled.SpinnerContainer>
            );
        }
        if (!provider) {
            return <Styled.RecipientIcon name="vmd-logo" size={2} />;
        }

        if (provider?.image?.url) {
            return <Styled.Image $size={2} src={provider.image.url} />;
        }

        if (provider?.provider_image_url) {
            return <Styled.Image $size={2} src={provider.provider_image_url} />;
        }

        return <Styled.RecipientIcon name="default-provider" size={2} />;
    }, [isLoadingProviders, provider]);

    const providerName = useMemo(() => {
        if (!provider) {
            const forProviderStaff: readonly MessageTypeKeys[] = [
                'PATIENTCASE_REFILL',
                'PATIENTCASE_LABS',
                'PATIENTCASE_IMAGING_RESULTS',
                'PATIENTCASE_GENERAL_TESTS',
            ];
            return messageType && forProviderStaff.includes(messageType)
                ? 'Village Medical Provider Staff'
                : 'Village Medical Admin Staff';
        }

        const providerDisplayname = getProviderDisplayName(provider);
        return providerDisplayname ? `${providerDisplayname} & Team` : 'Village Medical Admin Staff';
    }, [provider, messageType]);

    useEffect(() => {
        if (messageType === 'PATIENTCASE_REFILL' && medicationName) {
            setSubject(
                medicationName === 'Refill another prescription' ? medicationName : `Prescription Refill - ${medicationName}`
            );
        }
    }, [messageType, medicationName, setSubject]);

    const sendProviderIpc = useCallback((): void => {
        if (provider != null && !testResult) {
            sendToNative('onClickProvider', 'messaging');
        }
    }, [provider, testResult]);

    const providerIpcHandler = useCallback(
        (event: CustomEvent<MessageBase<{ readonly provider: Provider }>>) => {
            setProvider(event.detail.body?.provider);
        },
        [setProvider]
    ) as EventListener;

    useNativeIpc('onClickSelectProvider', providerIpcHandler);

    const onChangeSubject = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            setSubject(event.target.value);
        },
        [setSubject]
    );

    const onChangeMessage = useCallback(
        (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
            setMessage(event.target.value);
        },
        [setMessage]
    );

    const closeAttachmentModal = useCallback(() => {
        setIsAttachmentModalOpen(false);
    }, [setIsAttachmentModalOpen]);

    const openAttachmentModal = useCallback(() => {
        setIsAttachmentModalOpen(true);
    }, [setIsAttachmentModalOpen]);

    const openPreview = useCallback(
        (file: File) => {
            navigate('/attachment-preview', { state: { file } });
        },
        [navigate]
    );

    const openDeleteToast = useCallback(
        () => sendToNative('showToast', 'messaging', { text: 'Attachment successfully deleted' }),
        []
    );
    const throttledOpenDeleteToast = useThrottle(openDeleteToast, SendThrottleInterval);

    const getOpenPreviewHandler = (file: File) => () => openPreview(file);

    const onFileUpload = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            if (!event.target.files) return;
            const originalAttachmentList = Object.entries(attachments);
            const fileList = [...event.target.files].map((file) => [file.name, file] as readonly [string, File]);
            const newList = [...originalAttachmentList, ...fileList];
            if (Object.keys(newList).length > 10) {
                sendToNative('showToast', 'messaging', { text: 'Only 10 attachments allowed' });
            }
            const newAttachments = Object.fromEntries(newList.slice(0, 10)) as Record<File['name'], File>;
            setMessagingFields({ attachments: newAttachments });
            closeAttachmentModal();
        },
        [attachments, closeAttachmentModal, setMessagingFields]
    );

    const onFileDelete = useCallback(
        (fileName: string): void => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { [fileName]: value, ...newAttachmentsWithoutFile } = attachments;
            setMessagingFields({ attachments: newAttachmentsWithoutFile });
            throttledOpenDeleteToast();
        },
        [attachments, setMessagingFields, throttledOpenDeleteToast]
    );

    const getDeleteHandler = (fileName: string) => () => onFileDelete(fileName);

    const handleSendMessage = useCallback(() => {
        if (createNewMessageIsLoading) return;
        if (!messageTextAreaRef.current?.validate()) return;
        Countly.addEvent({ key: 'composeClickSend', segmentation: { messagingVersion: 2, source: 'messaging' } });

        const departmentId = provider?.department_ids?.[0];

        const messageHeaderMapping = {
            PATIENTCASE_REFILL: medicationName ? `Medication: ${medicationName}\n` : '',
        };

        const messageHeader = messageType
            ? Object.keys(messageHeaderMapping).includes(messageType)
                ? messageHeaderMapping[messageType]
                : ''
            : '';
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        const messagePayload = `${messageHeader ? `${messageHeader}\n` : ''}${message}${
            visitOption ? `\n\n${appPrefTitle}\n${visitOption}` : ''
        }`;

        const payload = {
            associated_imaging_result_id: testResult?.type === 'imagingresult' ? testResult.testResultId : undefined,
            associated_lab_result_id: testResult?.type === 'labresult' ? testResult.testResultId : undefined,
            department_id: testResult?.provider?.department_ids?.[0] ?? departmentId,
            message_type: messageType,
            provider_id: provider?.providerid,
            subject: subject === '' ? defaultSubject : subject,
            text: messagePayload,
        };

        createNewMessageMutate(payload);

        Countly.addEvent({
            key: 'composeMessageSent', // Track message creation attempt
            segmentation: {
                appointmentType: visitOption,
                departmentId,
                initializationSource,
                medicationName:
                    medicationName &&
                    (medicationName === 'Refill another prescription' ? medicationName : 'Refill existing prescription'),
                messageType,
                messagingVersion: 2,
                providerId: provider?.providerid,
                // parentSource - comes from the query string and is being used from mobile-bridge
                source: (parentSource ?? 'messaging') as CountlySegmentation['source'],
            },
        });
    }, [
        createNewMessageMutate,
        createNewMessageIsLoading,
        defaultSubject,
        initializationSource,
        medicationName,
        message,
        messageType,
        provider?.department_ids,
        provider?.providerid,
        subject,
        testResult?.testResultId,
        testResult?.type,
        visitOption,
        parentSource,
        testResult?.provider?.department_ids,
    ]);

    const throttledHandleSendMessage = useThrottle(handleSendMessage, SendThrottleInterval);

    useNativeIpc('sendNewMessage', throttledHandleSendMessage);

    const cancelButtonFunction = useCallback(() => {
        sendToNative('showPopup', 'messaging', {
            buttonCancel: {
                text: 'Cancel',
                type: 'primary',
            },
            buttonOK: {
                action: 'discardMessage',
                text: 'Discard',
                type: 'secondary',
            },
            text: 'Your message will not be saved',
            title: 'Discard message?',
        } as NativePopupState);
    }, []);

    useEffect(() => {
        const isValidInput = messageTextDebounced !== '';
        sendToNative('showDiscardPopup', 'messaging', {
            value: isValidInput,
        });
        setNavHeaderState((navHeaderState) => ({
            ...navHeaderState,
            backButton: !isValidInput,
            closeButton: isValidInput ? cancelButtonFunction : false,
        }));
    }, [cancelButtonFunction, messageTextDebounced, setNavHeaderState]);

    useNativeControls({
        ipcEvents: hasFeature('unifiedWebview')
            ? ['showBackButton', 'showSendButton']
            : ['hideControls', 'showCancelButton', 'showSendButton'],
        sendButtonFunction: throttledHandleSendMessage,
        source: 'messaging',
        suspend: suspendControlsIPC,
        title: 'New message',
    });

    const clearMessage = useCallback(() => {
        setMessage('');
    }, [setMessage]);

    useEffect(() => {
        document.addEventListener('navigateBack', clearMessage);

        return () => {
            document.removeEventListener('navigateBack', clearMessage);
        };
    }, [clearMessage]);

    const messagePlaceholder = useMemo(() => {
        if (messageType === 'PATIENTCASE_APPOINTMENT') {
            return 'Type your message. Please include relevant information about a scheduled or requested appointment (provider, date, time, etc).';
        }
        return 'Type your message';
    }, [messageType]);

    const showTestResultPreview = useCallback((): void => {
        if (testResult)
            navigate(
                `/test-result-messaging-preview/${testResult.type}/${testResult.testResultId}?hideControls=true&hideMessageButton=true`
            );
    }, [navigate, testResult]);

    if (isLoadingMessageThreads || isLoadingTestResult) {
        return <CenteredSpinner />;
    }

    return (
        <Styled.ComposeMessageRoot>
            <Styled.NewContainer>
                <Styled.RecipientContainer>
                    <span>To:</span>
                    <Styled.RecipientButton data-testid="provider-button" onClick={sendProviderIpc} variant="secondary">
                        {providerImage}
                        {providerName}
                        {provider && !testResult ? <Styled.ColoredIcon height={0.5} name="chevron-down" width={0.5} /> : null}
                    </Styled.RecipientButton>
                </Styled.RecipientContainer>
                <Styled.SubjectContainer>
                    <Styled.SubjectInput
                        data-testid="message-subject-field"
                        maxLength={SubjectLengthLimit}
                        onChange={onChangeSubject}
                        placeholder="Type your subject (optional)"
                        value={subject}
                    />
                    <Styled.SubjectCounter aria-label="Subject characters remaining">
                        {SubjectLengthLimit - subject.length}
                    </Styled.SubjectCounter>
                </Styled.SubjectContainer>

                <MessageTextArea
                    ref={messageTextAreaRef}
                    autoFocus={true}
                    disabled={createNewMessageIsLoading}
                    hasProvider={!!provider}
                    headerComponent={
                        testResult ? (
                            <Styled.CardButtonContainer>
                                <CardButton iconName="lab" onClick={showTestResultPreview}>
                                    Result: {testResult.testResultName ?? ''}
                                </CardButton>
                            </Styled.CardButtonContainer>
                        ) : null
                    }
                    message={message}
                    messageLengthLimit={MessageLengthLimit}
                    messageLengthWarning={MessageLengthWarning}
                    messagePlaceholder={messagePlaceholder}
                    minRows={3}
                    onChangeMessage={onChangeMessage}
                    visitOption={visitOption}
                />
                <Styled.AttachmentContainer>
                    {Object.keys(attachments ?? {}).map((fileName) => {
                        const file = attachments[fileName];
                        return (
                            <AttachmentInfo
                                key={fileName}
                                error={file.size > MaxFileSize ? 'File size exceeds 6 MB' : undefined}
                                file={file}
                                onDelete={getDeleteHandler(fileName)}
                                onTouch={getOpenPreviewHandler(file)}
                            />
                        );
                    })}
                </Styled.AttachmentContainer>
            </Styled.NewContainer>
            {isAttachmentsEnabled ? (
                <Styled.SendMessage>
                    <Styled.AttachmentButton
                        aria-label="Attach file"
                        onClick={openAttachmentModal}
                        size="small"
                        variant="tertiary"
                    >
                        <Icon name="attachment" size={1.5} />
                    </Styled.AttachmentButton>
                    <Button
                        data-testid="send-message-button"
                        disabled={createNewMessageIsLoading || isLoadingProviders}
                        onClick={throttledHandleSendMessage}
                        size="small"
                    >
                        Send
                    </Button>
                </Styled.SendMessage>
            ) : null}
            {!isAttachmentsEnabled ? (
                <Styled.SendMessage>
                    <Button
                        data-testid="send-message-button"
                        disabled={createNewMessageIsLoading || isLoadingProviders}
                        fullWidth={true}
                        onClick={throttledHandleSendMessage}
                        startIcon={<Icon name="sent" size={1.25} />}
                    >
                        Send Message
                    </Button>
                </Styled.SendMessage>
            ) : null}
            <AttachmentModal onClose={closeAttachmentModal} onFileUpload={onFileUpload} open={isAttachmentModalOpen} />
            <AttachmentFailModal
                onClose={navigateToMessaging}
                onShowFailedAttachments={navigateToThreadOnError}
                onSkip={navigateToMessaging}
                open={isFailAttachmentModalOpen}
            />
        </Styled.ComposeMessageRoot>
    );
};

export { ComposeMessage };
