import { Button } from '@village/ui';
import type { FC } from 'react';

import * as Styled from './styles';
import { BottomModal } from 'components/bottom-modal';
import { Icon } from 'components/icon';

interface Props {
    readonly open: boolean;
    readonly onClose: () => void;
    readonly onShowFailedAttachments: () => void;
    readonly onSkip: () => void;
}

export const AttachmentFailModal: FC<Props> = ({ open, onClose, onShowFailedAttachments, onSkip }) => (
    <BottomModal hideCloseButton={true} onClose={onClose} open={open}>
        <Styled.IconWrapper>
            <Icon name="danger" size={2} />
        </Styled.IconWrapper>
        <Styled.Title type="h4">At least one attachment failed to send.</Styled.Title>
        <Styled.Body type="body1">Message successful sent but some of the attachments were not uploaded correctly.</Styled.Body>
        <Styled.ButtonWrapper>
            <Button fullWidth={true} onClick={onShowFailedAttachments} size="medium" variant="primary">
                Show failed attachments
            </Button>
            <Button fullWidth={true} onClick={onSkip} size="medium" variant="link">
                Skip for now
            </Button>
        </Styled.ButtonWrapper>
    </BottomModal>
);
