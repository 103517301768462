import type { FC } from 'react';
import { memo } from 'react';

import { AttachmentPreviewContent } from './attachment-preview-page';
import { MainMasterPage } from 'pages/page-layouts';

const placeholders = {
    main: <AttachmentPreviewContent />,
};

const AttachmentPreviewPage: FC = memo(() => <MainMasterPage placeholders={placeholders} title="Attachments Preview" />);

export { AttachmentPreviewPage };
