import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    padding-bottom: 81px;
`;

const ButtonWrapper = styled.div`
    background-color: ${({ theme }) => theme.vui.colors.surface.white};
    padding: 16px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
`;

export { Wrapper, ButtonWrapper };
