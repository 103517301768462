import type { ChangeEvent, FC } from 'react';

import * as Styled from './styles';
import { BottomModal } from 'components/bottom-modal';
import { Icon } from 'components/icon';

interface Props {
    readonly open: boolean;
    readonly onClose: () => void;
    readonly onFileUpload: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const AttachmentModal: FC<Props> = ({ open, onClose, onFileUpload }) => (
    <BottomModal onClose={onClose} open={open}>
        <Styled.Title type="h3">Upload an attachment</Styled.Title>
        <Styled.Body type="body1">
            Select the files you want to share with your provider and staff. You can send up to 10 files, PNG, PDF and JPG file
            formats are supported. File size cannot exceed 6 MB.
        </Styled.Body>
        <Styled.ButtonWrapper>
            <Styled.AttachmentButton fullWidth={true} size="medium" variant="secondary">
                <Styled.Label htmlFor="photo-library-input">
                    <Icon name="photo-library" size={1.5} />
                    Photo library
                </Styled.Label>
                <Styled.VisuallyHidden>
                    <input
                        accept="image/png,image/jpg"
                        data-testid="photo-library-input"
                        id="photo-library-input"
                        multiple={true}
                        onChange={onFileUpload}
                        type="file"
                    />
                </Styled.VisuallyHidden>
            </Styled.AttachmentButton>
            <Styled.AttachmentButton fullWidth={true} size="medium" variant="secondary">
                <Styled.Label htmlFor="photo-camera-input">
                    <Icon name="photo-camera" size={1.5} />
                    Take a photo
                </Styled.Label>
                <Styled.VisuallyHidden>
                    <input
                        accept="image/png,image/jpg"
                        capture={true}
                        data-testid="photo-camera-input"
                        id="photo-camera-input"
                        onChange={onFileUpload}
                        type="file"
                    />
                </Styled.VisuallyHidden>
            </Styled.AttachmentButton>
            <Styled.AttachmentButton fullWidth={true} size="medium" variant="secondary">
                <Styled.Label htmlFor="file-input">
                    <Icon name="folder" size={1.5} />
                    Choose files
                </Styled.Label>
                <Styled.VisuallyHidden>
                    <input
                        accept="application/pdf"
                        data-testid="file-input"
                        id="file-input"
                        onChange={onFileUpload}
                        type="file"
                    />
                </Styled.VisuallyHidden>
            </Styled.AttachmentButton>
        </Styled.ButtonWrapper>
    </BottomModal>
);
