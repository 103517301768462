/* eslint-disable @typescript-eslint/no-invalid-void-type */
import type { AxiosProgressEvent } from 'axios';
import { useMutation } from 'react-query';
import type { UseMutationResult } from 'react-query';

import { messagingApi } from '../messaging-api-config';

export interface SuccessReplyResponse {
    readonly success: boolean;
}

interface UseMessageThreadAttachmentVariables {
    readonly onError?:
        | ((error: unknown, variables: UploadAttachmentVariables, context: unknown) => Promise<unknown> | void)
        | undefined;
    readonly onSuccess?: (
        data: SuccessReplyResponse,
        variables: UploadAttachmentVariables,
        context: unknown
    ) => Promise<unknown> | void;
    readonly onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
}
interface UploadAttachmentVariables {
    readonly formData: FormData;
    readonly messageThreadId: number;
}

const useMessageThreadAttachment = ({
    onError,
    onSuccess,
    onUploadProgress,
}: UseMessageThreadAttachmentVariables): UseMutationResult<SuccessReplyResponse, unknown, UploadAttachmentVariables> =>
    useMutation(
        async ({ messageThreadId, formData }: UploadAttachmentVariables): Promise<SuccessReplyResponse> => {
            const response = await messagingApi.postForm<SuccessReplyResponse>(
                `/message/${messageThreadId}/attachments`,
                formData,
                {
                    onUploadProgress,
                }
            );
            return response.data;
        },
        {
            onError,
            onSuccess,
        }
    );

export { useMessageThreadAttachment };
