import styled from 'styled-components';

const Preview = styled.object`
    height: 100%;
    width: 100%;
    object-fit: contain;
    border: 0;
`;

export { Preview };
