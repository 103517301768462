import type { FC, MouseEventHandler } from 'react';

import * as Styled from './styles';
import { Icon } from 'components/icon';

interface AttachmentsInfoProps {
    readonly onClick: MouseEventHandler<HTMLButtonElement>;
    readonly failedAttachments: readonly File[];
}

const AttachmentsSection: FC<AttachmentsInfoProps> = ({ onClick, failedAttachments }) => (
    <Styled.Wrapper onClick={onClick}>
        <Styled.IconWrapperLeft>
            <Icon name="attachment" size={1} />
        </Styled.IconWrapperLeft>
        <Styled.Title>Attachments ({failedAttachments.length})</Styled.Title>
        {failedAttachments.length > 0 && (
            <Styled.Warning>
                <Icon name="danger" size={1} />
                {failedAttachments.length} attachments failed to upload
            </Styled.Warning>
        )}
        <Styled.IconWrapperRight>
            <Icon name="chevron-right" size={1} />
        </Styled.IconWrapperRight>
    </Styled.Wrapper>
);

export { AttachmentsSection };
