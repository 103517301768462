import { Text } from '@village/ui';
import { rem } from 'polished';
import styled from 'styled-components';

const Title = styled(Text)`
    color: ${({ theme }) => theme.vui.colors.neutral.gray90};
    margin-bottom: ${rem(16)};
`;

const Body = styled(Text)`
    margin-bottom: ${rem(32)};
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${rem(12)};
`;

const IconWrapper = styled.div`
    display: grid;
    place-items: center;
    height: 56px;
    width: 56px;
    margin: 0 auto 32px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.vui.colors.surface.attention};
    color: ${({ theme }) => theme.vui.colors.icon.attention};
`;

export { Title, Body, ButtonWrapper, IconWrapper };
