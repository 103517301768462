import { useCallback, type FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import * as Styled from './styles';
import { useMessaging } from 'hooks/use-messaging';
import { useNativeControls } from 'hooks/use-native-controls';
import { sendToNative } from 'modules/ipc';
import type { NativeControlsIpcEvents } from 'modules/ipc/types';

interface LocationState {
    readonly file: File;
    readonly hideDeleteButton: boolean;
}

const AttachmentPreviewContent: FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const {
        setMessagingFields,
        messagingState: { attachments },
    } = useMessaging();
    const { file, hideDeleteButton } = location.state as LocationState;
    const isImage = file.type.startsWith('image/');

    const onFileDelete = useCallback((): void => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [file.name]: value, ...newAttachmentsWithoutFile } = attachments;
        setMessagingFields({ attachments: newAttachmentsWithoutFile });
        sendToNative('showToast', 'messaging', { text: 'Attachment successfully deleted' });
        navigate(-1);
    }, [attachments, file.name, navigate, setMessagingFields]);

    useNativeControls({
        attachmentDeleteFunction: onFileDelete,
        ipcEvents: ['showBackButton', ...(hideDeleteButton ? [] : ['showAttachmentDeleteButton' as NativeControlsIpcEvents])],
        source: 'health-records',
        title: file.name,
    });

    const source = URL.createObjectURL(file);

    return (
        <Styled.Preview
            as={isImage ? 'img' : 'iframe'}
            data-testid="preview-page"
            src={`${source}#toolbar=0&navpanes=0&scrollbar=0`}
        />
    );
};

export { AttachmentPreviewContent };
