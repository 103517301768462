import { useCallback, useEffect } from 'react';

import { useFeatureFlags } from './use-feature-flags';
import { useNavHeader } from './use-nav';
import { sendToNative } from 'modules/ipc';
import type { IpcSources, NativeControlsIpcEvents, NativePopupState } from 'modules/ipc/types';

interface NativeControlsIPC {
    readonly ipcEvents: readonly NativeControlsIpcEvents[];
    readonly source: IpcSources;
    readonly suspend?: boolean;
    readonly title?: string;
    readonly sendButtonFunction?: () => void;
    readonly replyButtonFunction?: () => void;
    readonly attachmentButtonFunction?: () => void;
    readonly attachmentDeleteFunction?: () => void;
    readonly shareButtonFunction?: () => void;
}

const useNativeControls = (controlsObject: NativeControlsIPC): void => {
    const {
        source,
        title,
        ipcEvents,
        suspend,
        sendButtonFunction,
        replyButtonFunction,
        attachmentButtonFunction,
        attachmentDeleteFunction,
        shareButtonFunction,
    } = controlsObject;

    const { hasFeature } = useFeatureFlags();
    const { setNavHeaderState, navigateToStartOfFlow } = useNavHeader();

    const stringifiedIpcEvents = JSON.stringify(ipcEvents);

    if (ipcEvents.includes('showSendButton') && sendButtonFunction === undefined) {
        throw new Error('Please provide a send message function.');
    }

    if (ipcEvents.includes('showReplyMessage') && replyButtonFunction === undefined) {
        throw new Error('Please provide a send reply function.');
    }

    if (ipcEvents.includes('showAttachmentButton') && attachmentButtonFunction === undefined) {
        throw new Error('Please provide an attachment button function.');
    }

    if (ipcEvents.includes('showAttachmentDeleteButton') && attachmentDeleteFunction === undefined) {
        throw new Error('Please provide an attachment button function.');
    }

    if (ipcEvents.includes('showShareButton') && shareButtonFunction === undefined) {
        throw new Error('Please provide a share function.');
    }

    const cancelButtonFunction = useCallback(() => {
        if (ipcEvents.includes('showSendButton')) {
            sendToNative('showPopup', source, {
                buttonCancel: {
                    text: 'Cancel',
                    type: 'primary',
                },
                buttonOK: {
                    action: 'discardMessage',
                    text: 'Discard',
                    type: 'secondary',
                },
                text: 'Your message will not be saved',
                title: 'Discard message?',
            } as NativePopupState);
        } else {
            navigateToStartOfFlow();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stringifiedIpcEvents, navigateToStartOfFlow, source]);

    useEffect(() => {
        document.addEventListener('discardMessage', navigateToStartOfFlow);
        return () => {
            document.removeEventListener('discardMessage', navigateToStartOfFlow);
        };
    }, [navigateToStartOfFlow]);

    const sendIPCEventsToNative = useCallback(() => {
        if (hasFeature('unifiedWebview')) {
            // reset to the default state
            sendToNative('hideControls', source);
        }
        // send the native controls IPC events
        ipcEvents.forEach((ipcEvent) => {
            sendToNative(ipcEvent, source);
        });
        // set the native title
        sendToNative('setNativeTitle', source, {
            value: title ?? '',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stringifiedIpcEvents, source, title]);

    useEffect(() => {
        if (suspend) return;

        setNavHeaderState({
            attachmentButton: ipcEvents.includes('showAttachmentButton') && attachmentButtonFunction,
            attachmentDeleteButton: ipcEvents.includes('showAttachmentDeleteButton') && attachmentDeleteFunction,
            backButton: ipcEvents.includes('showBackButton') || ipcEvents.includes('showControls'),
            closeButton:
                ipcEvents.includes('showCancelButton') || ipcEvents.includes('showControls') ? cancelButtonFunction : false,
            headerText: title ?? '',
            replyButton: ipcEvents.includes('showReplyMessage') && replyButtonFunction,
            sendButton: ipcEvents.includes('showSendButton')
                ? // eslint-disable-next-line @typescript-eslint/no-empty-function
                  sendButtonFunction ?? (() => {})
                : false,
            shareButton: ipcEvents.includes('showShareButton') && shareButtonFunction,
        });

        sendIPCEventsToNative();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stringifiedIpcEvents, source, suspend, title, cancelButtonFunction, replyButtonFunction, sendButtonFunction]);

    const handleVisibilityChange = useCallback(() => {
        if (document.visibilityState === 'visible') {
            sendIPCEventsToNative();
        }
    }, [sendIPCEventsToNative]);

    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [handleVisibilityChange]);
};

export { useNativeControls };
