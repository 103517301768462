import type { FC, PropsWithChildren } from 'react';

import * as Styled from './styles';

interface Props {
    readonly hideCloseButton?: boolean;
    readonly open: boolean;
    readonly onClose: () => void;
}

export const BottomModal: FC<PropsWithChildren<Props>> = ({ open, onClose, hideCloseButton, children }) => (
    <Styled.Modal hideCloseButton={hideCloseButton} onClose={onClose} open={open}>
        <Styled.Wrapper>{children}</Styled.Wrapper>
    </Styled.Modal>
);
