import { Modal as VuiModal, Text } from '@village/ui';
import { rem } from 'polished';
import styled from 'styled-components';

const Modal = styled(VuiModal)`
    align-items: end;
    padding: 0;

    & [role='dialog'] {
        width: 100%;
        border-radius: ${rem(12)} ${rem(12)} 0 0;
    }
`;

const Wrapper = styled.div`
    padding: ${rem(24)} ${rem(16)};
    color: ${({ theme }) => theme.vui.colors.text.graySubtle};
`;

const Title = styled(Text)`
    color: ${({ theme }) => theme.vui.colors.neutral.gray90};
    margin-bottom: ${rem(16)};
`;

export { Modal, Wrapper, Title };
