import type { AxiosInstance, AxiosRequestConfig } from 'axios';
import MockAdapter from 'axios-mock-adapter';

import { DEPARTMENTS_SUCCESS_RESPONSE } from './departments';
import { ENCOUNTER_DETAILS_SUCCESS_RESPONSE } from './encounter-details';
import { ENCOUNTERS_SUCCESS_RESPONSE } from './encounters';
import { IMAGING_RESULT_SUCCESS_RESPONSE } from './imaging-result-details';
import { IMAGING_RESULTS_SUCCESS_RESPONSE } from './imaging-results';
import {
    LAB_RESULT_HEMOGLOBIN_SUCCESS_RESPONSE,
    LAB_RESULT_HEP_C_SUCCESS_RESPONSE,
    LAB_RESULT_CMP_SUCCESS_RESPONSE,
    LAB_RESULT_NO_RESULTS_SUCCESS_RESPONSE,
    LAB_RESULT_NO_PROVIDER_SUCCESS_RESPONSE,
    LAB_RESULT_NO_PROVIDER_NOTE_SUCCESS_RESPONSE,
} from './lab-result';
import {
    LABS_SUCCESS_RESPONSE,
    LAB_RESULT_ID_WITH_ATTACHMENT_HEMOGLOBIN,
    LAB_RESULT_ID_WITHOUT_ATTACHMENT_HEP_C,
    LAB_RESULT_ID_WITHOUT_ATTACHMENT_CMP,
    LAB_RESULT_ID_NO_RESULTS,
    LAB_RESULT_ID_NO_PROVIDER,
    LAB_RESULT_ID_NO_PROVIDER_NOTE,
} from './labs';
import { LETTERS_RESPONSE } from './letters';
import { LETTERS_RESPONSE_PAGE_2 } from './letters-details';
import { MEDICATIONS_SUCCESS_RESPONSE } from './medications';
import { MESSAGE_LAB_RESULT_NO_THREAD, MESSAGE_LAB_RESULT_THREAD_SUCCESS_RESPONSE_2 } from './message-lab-result-thread';
import {
    MESSAGE_THREAD_SUCCESS_RESPONSE_1,
    MESSAGE_THREAD_SUCCESS_RESPONSE_2,
    MESSAGE_POST_SUCCESS_RESPONSE,
} from './message-thread';
import { MESSAGE_TYPE_SUCCESS_RESPONSE } from './message-types';
import { MESSAGES_SUCCESS_RESPONSE, MESSAGES_SUCCESS_RESPONSE_FETCH_ADDITIONAL } from './messages';
import { PHARMACIES_SUCCESS_RESPONSE } from './pharmacies';
import { PROVIDERS_SUCCESS_RESPONSE } from './providers';
import { UNREAD_COUNT_SUCCESS_RESPONSE } from './unread-count';
import { UPCOMING_APPOINTMENTS_RESPONSE } from './upcoming-appointments';
import { VACCINE_HISTORY_RESPONSE } from './vaccine-history';

const OK_HTTP_CODE = 200;

const HEALTH_RECORDS_RESPONSES = [
    {
        body: MEDICATIONS_SUCCESS_RESPONSE,
        path: '/medications?medication_types=ACTIVE&medication_types=HISTORICAL',
        statusCode: OK_HTTP_CODE,
    },
    {
        body: LABS_SUCCESS_RESPONSE,
        path: '/labs/?show_portal_only=true',
        statusCode: OK_HTTP_CODE,
    },
    {
        body: IMAGING_RESULTS_SUCCESS_RESPONSE,
        path: '/imaging_results/',
        statusCode: OK_HTTP_CODE,
    },
    {
        body: IMAGING_RESULT_SUCCESS_RESPONSE,
        path: '/imaging_results/9065',
        statusCode: OK_HTTP_CODE,
    },
    {
        body: LAB_RESULT_HEMOGLOBIN_SUCCESS_RESPONSE,
        path: `/labs/${LAB_RESULT_ID_WITH_ATTACHMENT_HEMOGLOBIN}`,
        statusCode: OK_HTTP_CODE,
    },
    {
        body: LAB_RESULT_HEP_C_SUCCESS_RESPONSE,
        path: `/labs/${LAB_RESULT_ID_WITHOUT_ATTACHMENT_HEP_C}`,
        statusCode: OK_HTTP_CODE,
    },
    {
        body: LAB_RESULT_CMP_SUCCESS_RESPONSE,
        path: `/labs/${LAB_RESULT_ID_WITHOUT_ATTACHMENT_CMP}`,
        statusCode: OK_HTTP_CODE,
    },
    {
        body: LAB_RESULT_NO_RESULTS_SUCCESS_RESPONSE,
        path: `/labs/${LAB_RESULT_ID_NO_RESULTS}`,
        statusCode: OK_HTTP_CODE,
    },
    {
        body: LAB_RESULT_NO_PROVIDER_SUCCESS_RESPONSE,
        path: `/labs/${LAB_RESULT_ID_NO_PROVIDER}`,
        statusCode: OK_HTTP_CODE,
    },
    {
        body: LAB_RESULT_NO_PROVIDER_NOTE_SUCCESS_RESPONSE,
        path: `/labs/${LAB_RESULT_ID_NO_PROVIDER_NOTE}`,
        statusCode: OK_HTTP_CODE,
    },
    {
        body: ENCOUNTERS_SUCCESS_RESPONSE,
        path: `/encounters`,
        statusCode: OK_HTTP_CODE,
    },
    {
        body: ENCOUNTER_DETAILS_SUCCESS_RESPONSE,
        path: /\/encounters\/\d+/,
        statusCode: OK_HTTP_CODE,
    },
    {
        body: { results: VACCINE_HISTORY_RESPONSE },
        path: '/vaccines',
        statusCode: OK_HTTP_CODE,
    },
    {
        body: LETTERS_RESPONSE,
        path: '/letters/details?limit=12',
        statusCode: OK_HTTP_CODE,
    },
    {
        body: LETTERS_RESPONSE_PAGE_2,
        path: '/letters/details?offset=12&limit=5',
        statusCode: OK_HTTP_CODE,
    },
    {
        body: PHARMACIES_SUCCESS_RESPONSE,
        path: '/pharmacies/preferred',
        statusCode: OK_HTTP_CODE,
    },
];

const mockHealthRecords = (axiosInstance: AxiosInstance): void => {
    const healthRecordsApiMock = new MockAdapter(axiosInstance, { delayResponse: 2000, onNoMatch: 'passthrough' });
    HEALTH_RECORDS_RESPONSES.forEach((response) =>
        healthRecordsApiMock.onGet(response.path).reply(response.statusCode, response.body)
    );
};

const SCHEDULING_RECORDS_RESPONSES = [
    {
        body: UPCOMING_APPOINTMENTS_RESPONSE,
        path: '/appointments/',
        statusCode: OK_HTTP_CODE,
    },
    {
        body: DEPARTMENTS_SUCCESS_RESPONSE,
        path: '/departments/',
        statusCode: OK_HTTP_CODE,
    },
];

const mockScheduling = (axiosInstance: AxiosInstance): void => {
    const schedulingApiMock = new MockAdapter(axiosInstance, { delayResponse: 2000, onNoMatch: 'passthrough' });
    SCHEDULING_RECORDS_RESPONSES.forEach((response) =>
        schedulingApiMock.onGet(response.path).reply(response.statusCode, response.body)
    );
};

// eslint-disable-next-line no-promise-executor-return
const sleep = async (value: number): Promise<unknown> => new Promise((resolve) => setTimeout(resolve, value));

const mockMessaging = (axiosInstance: AxiosInstance): void => {
    const messagingApiMock = new MockAdapter(axiosInstance, { delayResponse: 2000, onNoMatch: 'throwException' });
    messagingApiMock.onGet('/message/1?source=provider').reply(OK_HTTP_CODE, MESSAGE_THREAD_SUCCESS_RESPONSE_1);
    messagingApiMock
        .onGet('/message/1?source=provider&only_ready_documents=true')
        .reply(OK_HTTP_CODE, MESSAGE_THREAD_SUCCESS_RESPONSE_1);
    messagingApiMock.onGet('/message/2?source=provider').reply(OK_HTTP_CODE, MESSAGE_THREAD_SUCCESS_RESPONSE_2);
    messagingApiMock
        .onGet('/message/2?source=provider&only_ready_documents=true')
        .reply(OK_HTTP_CODE, MESSAGE_THREAD_SUCCESS_RESPONSE_2);
    messagingApiMock.onGet('/message/1').reply(OK_HTTP_CODE, MESSAGE_POST_SUCCESS_RESPONSE);
    messagingApiMock.onGet('/message/1?source=labresult').reply(OK_HTTP_CODE, MESSAGE_LAB_RESULT_NO_THREAD);
    messagingApiMock
        .onGet('/message/1?source=labresult&only_ready_documents=true')
        .reply(OK_HTTP_CODE, MESSAGE_LAB_RESULT_NO_THREAD);
    messagingApiMock.onGet('/message/2?source=labresult').reply(OK_HTTP_CODE, MESSAGE_LAB_RESULT_THREAD_SUCCESS_RESPONSE_2);
    messagingApiMock
        .onGet('/message/2?source=labresult&only_ready_documents=true')
        .reply(OK_HTTP_CODE, MESSAGE_LAB_RESULT_THREAD_SUCCESS_RESPONSE_2);
    messagingApiMock.onPut('/message/1/mark_read?source=provider').reply(OK_HTTP_CODE, MESSAGE_POST_SUCCESS_RESPONSE);
    messagingApiMock.onPut('/message/2/mark_read?source=labresult').reply(OK_HTTP_CODE, MESSAGE_POST_SUCCESS_RESPONSE);
    messagingApiMock.onGet(/\/message\/\?only_ready_documents=true\/*/).reply((config: AxiosRequestConfig) => {
        const { folder, limit, offset, source } = config.params;
        if (folder === 'inbox' && limit === 10 && offset === 0) {
            return [OK_HTTP_CODE, MESSAGES_SUCCESS_RESPONSE];
        }
        if (folder === 'inbox' && limit === 9999 && offset === 0 && source === 'labresult') {
            return [OK_HTTP_CODE, MESSAGE_LAB_RESULT_THREAD_SUCCESS_RESPONSE_2];
        }
        return [OK_HTTP_CODE, MESSAGES_SUCCESS_RESPONSE];
    });
    messagingApiMock
        .onGet('/message/3?source=provider&only_ready_documents=true')
        .reply(OK_HTTP_CODE, MESSAGE_LAB_RESULT_THREAD_SUCCESS_RESPONSE_2);
    messagingApiMock
        .onGet('/message/?source=imagingresult&source=labresult&source=provider')
        .reply((config: AxiosRequestConfig) => {
            const { folder, limit, offset, source } = config.params;
            if (folder === 'inbox' && limit === 10 && offset === 0) {
                return [OK_HTTP_CODE, MESSAGES_SUCCESS_RESPONSE];
            }
            if (folder === 'inbox' && limit === 9999 && offset === 0 && source === 'labresult') {
                return [OK_HTTP_CODE, MESSAGE_LAB_RESULT_THREAD_SUCCESS_RESPONSE_2];
            }
            return [OK_HTTP_CODE, MESSAGES_SUCCESS_RESPONSE];
        });
    messagingApiMock
        .onGet('/message/?source=imagingresult&source=labresult')
        .reply(() => [OK_HTTP_CODE, MESSAGES_SUCCESS_RESPONSE]);

    messagingApiMock.onGet('/message/?only_ready_documents=true').reply(OK_HTTP_CODE, MESSAGES_SUCCESS_RESPONSE);
    messagingApiMock
        .onGet('/message/?only_ready_documents=true&folder=inbox&limit=10&offset=10')
        .reply(OK_HTTP_CODE, MESSAGES_SUCCESS_RESPONSE_FETCH_ADDITIONAL);
    messagingApiMock.onGet(/\/message\/unread\/*/).reply(OK_HTTP_CODE, UNREAD_COUNT_SUCCESS_RESPONSE);
    messagingApiMock.onGet('/message/unread?only_ready_documents=true').reply(OK_HTTP_CODE, UNREAD_COUNT_SUCCESS_RESPONSE);
    messagingApiMock
        .onGet('/message/unread?source=imagingresult&source=labresult&source=provider')
        .reply(OK_HTTP_CODE, UNREAD_COUNT_SUCCESS_RESPONSE);
    messagingApiMock.onGet('/message/types').reply(OK_HTTP_CODE, MESSAGE_TYPE_SUCCESS_RESPONSE);
    messagingApiMock.onGet('/message/providers').reply(OK_HTTP_CODE, PROVIDERS_SUCCESS_RESPONSE);
    messagingApiMock.onGet('/message/appointments').reply(OK_HTTP_CODE, UPCOMING_APPOINTMENTS_RESPONSE);
    messagingApiMock.onPost('/message/').reply(OK_HTTP_CODE, {
        isSuccess: true,
        message_thread_id: 3,
    });
    messagingApiMock.onPost('/message/1').reply(OK_HTTP_CODE, { success: true });
    messagingApiMock.onGet('/pharmacies/preferred').reply(OK_HTTP_CODE, PHARMACIES_SUCCESS_RESPONSE);
    messagingApiMock.onPost('/message/2').reply(OK_HTTP_CODE, { success: true });
    messagingApiMock.onPost('/message/1/attachments').reply((config: AxiosRequestConfig) => {
        const TotalSize = 1024;
        [0, 0.2, 0.4, 0.6, 0.8, 1].forEach(async (progress) => {
            await sleep(500);
            if (config.onUploadProgress) {
                config.onUploadProgress({ bytes: TotalSize, loaded: TotalSize * progress });
            }
        });
        return Math.random() > 0.5 ? [OK_HTTP_CODE, { success: true }] : [400, { success: false }];
    });
    messagingApiMock.onPost('/message/3/attachments').reply((config: AxiosRequestConfig) => {
        const TotalSize = 1024;
        [0, 0.2, 0.4, 0.6, 0.8, 1].forEach(async (progress) => {
            await sleep(500);
            if (config.onUploadProgress) {
                config.onUploadProgress({ bytes: TotalSize, loaded: TotalSize * progress });
            }
        });
        return Math.random() > 0.5 ? [OK_HTTP_CODE, { success: true }] : [400, { success: false }];
    });
};

export { mockHealthRecords, mockMessaging, mockScheduling };
