import type { FC } from 'react';
import { memo } from 'react';

import { AttachmentsContent } from './attachments-page';
import { MainMasterPage } from 'pages/page-layouts';

const placeholders = {
    main: <AttachmentsContent />,
};

const AttachmentsPage: FC = memo(() => <MainMasterPage placeholders={placeholders} title="Attachments" />);

export { AttachmentsPage };
