import { useToggle } from '@village/tools';
import { Fragment, useCallback, forwardRef, useImperativeHandle } from 'react';
import type { ReactNode } from 'react';

import { LengthWarning } from './length-warning';
import * as Styled from './styles';

export interface MessageTextAreaProps {
    readonly messageLengthLimit: number;
    readonly messageLengthWarning: number;
    readonly message: string;
    readonly onChangeMessage: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    readonly visitOption?: string;
    readonly messagePlaceholder: string;
    readonly hasProvider?: boolean;
    readonly minRows?: number;
    readonly maxRows?: number;
    readonly disabled?: boolean;
    readonly onFocus?: () => void;
    readonly onBlur?: () => void;
    readonly autoFocus?: boolean;
    readonly headerComponent?: ReactNode;
}

export interface ValidateHandle {
    readonly validate: () => boolean;
}

export const appPrefTitle = 'Appt Preference';

export const MessageTextArea = forwardRef<ValidateHandle, MessageTextAreaProps>(
    (
        {
            messageLengthLimit,
            messageLengthWarning,
            message,
            visitOption,
            onChangeMessage,
            messagePlaceholder,
            hasProvider = false,
            minRows,
            maxRows,
            disabled = false,
            onFocus,
            onBlur,
            autoFocus = false,
            headerComponent,
        },
        ref
    ) => {
        const { value: displayEditNote, switchOff: hideEditNote } = useToggle(true);
        const {
            value: displayMissingMessageWarning,
            switchOff: hideMissingMessageWarning,
            switchOn: showMissingMessageWarning,
        } = useToggle(false);

        const dismissNote = useCallback(() => {
            hideEditNote();
            hideMissingMessageWarning();
            if (onFocus) onFocus();
        }, [hideEditNote, hideMissingMessageWarning, onFocus]);

        const validate = useCallback((): boolean => {
            if (message.trim() === '') {
                showMissingMessageWarning();
                return false;
            }
            if (message.length > messageLengthLimit) {
                return false;
            }
            return true;
        }, [message, messageLengthLimit, showMissingMessageWarning]);

        useImperativeHandle(
            ref,
            () => ({
                validate,
            }),
            [validate]
        );

        return (
            <Fragment>
                {displayEditNote ? (
                    <Styled.Warning data-testid="edit-label">
                        <Styled.WarningIcon name="warning" />
                        Note: tap the message to edit
                    </Styled.Warning>
                ) : null}
                <LengthWarning
                    messageLength={message.length}
                    messageLengthLimit={messageLengthLimit}
                    messageLengthWarning={messageLengthWarning}
                />
                {displayMissingMessageWarning ? (
                    <Styled.Warning data-testid="blank-message-warning" type="error">
                        <Styled.ErrorIcon name="danger" />
                        Please enter message before sending
                    </Styled.Warning>
                ) : null}
                {headerComponent}
                <Styled.MessageContainer>
                    {visitOption ? (
                        <Fragment>
                            <Styled.MessageWhenPreferences>
                                <Styled.PreferencesTitle>Message</Styled.PreferencesTitle>
                                <Styled.MessageInput
                                    autoFocus={autoFocus}
                                    data-testid="message-input-field"
                                    disabled={disabled}
                                    maxRows={maxRows}
                                    minRows={minRows}
                                    onBlur={onBlur}
                                    onChange={onChangeMessage}
                                    onFocus={dismissNote}
                                    placeholder={messagePlaceholder}
                                    value={message}
                                />
                            </Styled.MessageWhenPreferences>
                            {!hasProvider ? (
                                <Styled.MessageWhenPreferences data-testid="no-provider-preferences">
                                    <Styled.PreferencesTitle>Provider</Styled.PreferencesTitle>
                                    <Styled.PreferencesText>No preference</Styled.PreferencesText>
                                </Styled.MessageWhenPreferences>
                            ) : null}
                            <div data-testid="visit-options-section">
                                <Styled.PreferencesTitle>{appPrefTitle}</Styled.PreferencesTitle>
                                <Styled.PreferencesText>{visitOption ?? ''}</Styled.PreferencesText>
                            </div>
                        </Fragment>
                    ) : (
                        <Styled.MessageInput
                            autoFocus={autoFocus}
                            data-testid="message-input-field"
                            disabled={disabled}
                            maxRows={maxRows}
                            minRows={minRows}
                            onBlur={onBlur}
                            onChange={onChangeMessage}
                            onFocus={dismissNote}
                            placeholder={messagePlaceholder}
                            // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
                            style={{ overflow: 'auto' }}
                            value={message}
                        />
                    )}
                </Styled.MessageContainer>
            </Fragment>
        );
    }
);
