import type { FC, PropsWithChildren } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation, useNavigationType } from 'react-router-dom';

import { NavHeaderContext } from './nav-header-context';
import { useMessaging } from 'hooks/use-messaging';
import { sendToNative } from 'modules/ipc';

interface NavHeaderState {
    readonly backButton?: boolean;
    readonly closeButton?: false | (() => void);
    readonly sendButton?: false | (() => void);
    readonly headerText?: string;
    readonly replyButton?: false | (() => void);
    readonly attachmentButton?: false | (() => void);
    readonly attachmentDeleteButton?: false | (() => void);
    readonly shareButton?: false | (() => void);
}

const homeRoutes = new Set(['/messaging', '/medications', '/test-results']);

const NavHeaderProvider: FC<PropsWithChildren> = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const {
        messagingState: { messageInitiatedFrom },
        resetMessagingState,
    } = useMessaging();
    const [navHeaderState, setNavHeaderState] = useState<NavHeaderState>({
        attachmentButton: false,
        attachmentDeleteButton: false,
        backButton: false,
        closeButton: false,
        headerText: '',
        sendButton: false,
    });
    const [showingHeaderNav, setShowingHeaderNav] = useState(true);
    const [historyState, setHistoryState] = useState<readonly string[]>([]);
    const action = useNavigationType();

    const goHome = useCallback(() => {
        navigate('/messaging');
    }, [navigate]);

    const forceStepBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    const navigateBack = useCallback(() => {
        if (historyState.length === 1) {
            sendToNative('closeWebview', 'health-records');
        } else {
            navigate(-1);
        }
    }, [historyState, navigate]);

    useEffect(() => {
        if (homeRoutes.has(location.pathname)) {
            setHistoryState([location.pathname]);
        } else if (action === 'POP') {
            setHistoryState((oldState) => {
                const locationIndex = oldState.lastIndexOf(location.pathname);
                return locationIndex === -1 ? oldState.slice(0, -1) : oldState.slice(0, locationIndex + 1);
            });
        } else if (action === 'REPLACE') {
            setHistoryState((oldState) => [...oldState.slice(0, -1), location.pathname]);
        } else {
            setHistoryState((oldState) => [...oldState, location.pathname]);
        }
        // we don't want this to run everytime historyState changes, only location
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
        sendToNative('navigation', 'health-records', {
            canGoBack: historyState.length > 1,
            currentPage: historyState[historyState.length - 1],
        });
    }, [historyState]);

    useEffect(() => {
        document.addEventListener('navigateBack', forceStepBack);
        document.addEventListener('cancelButtonClicked', goHome);
        setHistoryState([...historyState, location.pathname]);

        return () => {
            document.removeEventListener('navigateBack', forceStepBack);
            document.removeEventListener('cancelButtonClicked', goHome);
        };
        // run this only once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const resetHistoryState = useCallback(() => {
        setHistoryState([location.pathname]);
    }, [location]);

    const navigateToStartOfFlow = useCallback(() => {
        resetMessagingState();
        if (messageInitiatedFrom === 'native') {
            sendToNative('closeWebview', 'health-records');
        } else if (messageInitiatedFrom) {
            const targetHistoryStateIndex = historyState.indexOf(messageInitiatedFrom);
            if (targetHistoryStateIndex !== -1) {
                const stepsBack = historyState.length - targetHistoryStateIndex - 1;
                navigate(-stepsBack);
            } else {
                navigate(messageInitiatedFrom, { replace: true });
            }
        } else {
            navigate('/messaging'); // fallback
        }
    }, [historyState, messageInitiatedFrom, navigate, resetMessagingState]);

    const navContextValue = useMemo(
        () => ({
            navHeaderState,
            navigateBack,
            navigateToStartOfFlow,
            resetHistoryState,
            setNavHeaderState,
            setShowingHeaderNav,
            showingHeaderNav,
        }),
        [navHeaderState, navigateBack, resetHistoryState, showingHeaderNav, navigateToStartOfFlow]
    );

    return <NavHeaderContext.Provider value={navContextValue}>{children}</NavHeaderContext.Provider>;
};

export type { NavHeaderState };
export { NavHeaderProvider };
