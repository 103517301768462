/* eslint-disable @typescript-eslint/no-invalid-void-type */
import { useMutation } from 'react-query';
import type { UseMutationResult } from 'react-query';

import { messagingApi } from '../messaging-api-config';
import type { MessageSource } from 'types';

interface ReplyResponse {
    readonly success: boolean;
}

interface MutateReadParams {
    readonly messageThreadId: number | string;
    readonly messageThreadSource: MessageSource;
}

interface UseMessageThreadReplyVariables {
    readonly onError?: ((error: unknown, variables: unknown, context: unknown) => Promise<unknown> | void) | undefined;
    readonly onSuccess?: (data: ReplyResponse, variables: unknown, context: unknown) => Promise<unknown> | void;
    readonly messageThreadId: number | string;
}

const useMessageThreadReply = ({
    onError,
    onSuccess,
    messageThreadId,
}: UseMessageThreadReplyVariables): UseMutationResult<ReplyResponse> =>
    useMutation(
        async (replyText) => {
            const response = await messagingApi.post<ReplyResponse>(`/message/${messageThreadId}`, { text: replyText });
            return response.data;
        },
        {
            onError,
            onSuccess,
        }
    );

const useMessageThreadMarkRead = (): UseMutationResult<ReplyResponse, unknown, MutateReadParams> =>
    useMutation(async ({ messageThreadId, messageThreadSource }) => {
        const response = await messagingApi.put<ReplyResponse>(
            `/message/${messageThreadId}/mark_read?source=${messageThreadSource}`
        );
        return response.data;
    });

export { useMessageThreadReply, useMessageThreadMarkRead };
