import { Text } from '@village/ui';
import { rem } from 'polished';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    flex-shrink: 0;
    gap: ${rem(12)};
    height: 52px;
    border: 1px solid ${({ theme }) => theme.vui.colors.neutral.gray10};
    border-radius: 4px;
    overflow: hidden;
`;

const Image = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
`;

const IconWrapper = styled.div`
    height: 100%;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.vui.colors.neutral.gray60};
    background-color: ${({ theme }) => theme.vui.colors.neutral.gray5};
`;

const FileInfoWrapper = styled.div`
    flex-grow: 1;
    min-width: 0;
`;

const FileName = styled.p<{ readonly $extension?: string }>`
    color: ${({ theme }) => theme.vui.colors.neutral.gray90};
    font-size: 14px;
    line-height: 1.4;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &::before {
        content: '${({ $extension = '' }) => $extension}';
        float: right;
    }
`;

const FileCaption = styled(Text)<{
    readonly $error?: boolean;
    readonly $warning?: boolean;
}>`
    color: ${({ theme, $error, $warning }) =>
        $error ? theme.vui.colors.text.error : $warning ? theme.vui.colors.text.attention : theme.vui.colors.text.graySubtle};
`;

const ActionWrapper = styled.div`
    margin-right: ${rem(12)};
`;

export { Wrapper, Image, IconWrapper, FileInfoWrapper, FileName, FileCaption, ActionWrapper };
