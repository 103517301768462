import { Text } from '@village/ui';
import { rem } from 'polished';
import styled from 'styled-components';

const Wrapper = styled.button`
    all: unset;
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    grid-template-rows: 1fr 1fr;
    padding: ${rem(16)};
    column-gap: ${rem(8)};
    border-top: 1px solid ${({ theme }) => theme.vui.colors.border.divider.default};
    border-bottom: 1px solid ${({ theme }) => theme.vui.colors.border.divider.default};
`;

const IconWrapperLeft = styled.div`
    grid-column: 1/2;
    grid-row: 1/3;
    align-self: center;
    border: 1px solid black;
    padding: 0 3px;
    border-radius: 50px;
`;

const Title = styled(Text).attrs(() => ({ type: 'sub1' }))`
    grid-column: 2/3;
    grid-row: 1/2;
`;

const Warning = styled(Text).attrs(() => ({ type: 'sub2' }))`
    grid-column: 2/3;
    grid-row: 2/3;
    color: ${({ theme }) => theme.vui.colors.text.error};
    display: flex;
    gap: ${rem(4)};
`;

const IconWrapperRight = styled.div`
    grid-column: 3/4;
    grid-row: 1/3;
    align-self: center;
`;

export { Wrapper, IconWrapperLeft, IconWrapperRight, Title, Warning };
