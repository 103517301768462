import { memo } from 'react';
import type { FC, MouseEventHandler, SVGProps } from 'react';

import { ReactComponent as CallUsIcon } from './icons/CTA-call-us.svg';
import { ReactComponent as DirectionsIcon } from './icons/CTA-get-directions.svg';
import { ReactComponent as AttachmentIcon } from './icons/attachment-icon.svg';
import { ReactComponent as BackIcon } from './icons/back-icon.svg';
import { ReactComponent as BandAidIcon } from './icons/band-aid-icon.svg';
import { ReactComponent as BloodPressureIcon } from './icons/blood-pressure-icon.svg';
import { ReactComponent as BmiIcon } from './icons/bmi-icon.svg';
import { ReactComponent as BuildingIcon } from './icons/building.svg';
import { ReactComponent as ChevronDownIcon } from './icons/chevron-down-icon.svg';
import { ReactComponent as ChevronRightIcon } from './icons/chevron-right-icon.svg';
import { ReactComponent as ChevronUpIcon } from './icons/chevron-up-icon.svg';
import { ReactComponent as CloseIcon } from './icons/close-icon.svg';
import { ReactComponent as CloudQuestion } from './icons/cloud-question.svg';
import { ReactComponent as ContextIcon } from './icons/context.svg';
import { ReactComponent as DangerIcon } from './icons/danger.svg';
import { ReactComponent as DefaultProvider } from './icons/default-provider.svg';
import { ReactComponent as EmailIcon } from './icons/email-icon.svg';
import { ReactComponent as FileColoredIcon } from './icons/file-colored.svg';
import { ReactComponent as FileIcon } from './icons/file.svg';
import { ReactComponent as FolderOpenIcon } from './icons/folder-open-icon.svg';
import { ReactComponent as HeightIcon } from './icons/height-icon.svg';
import { ReactComponent as HelpIcon } from './icons/help.svg';
import { ReactComponent as HouseArrowOut } from './icons/house-arrow-out.svg';
import { ReactComponent as TestResults } from './icons/ic-test-results.svg';
import { ReactComponent as InboxIcon } from './icons/inbox-icon.svg';
import { ReactComponent as LabIcon } from './icons/lab-icon.svg';
import { ReactComponent as LabResultIcon } from './icons/lab-result-icon.svg';
import { ReactComponent as LabsIcon } from './icons/labs.svg';
import { ReactComponent as MedicalQuestionIcon } from './icons/medical-question.svg';
import { ReactComponent as MedicationIcon } from './icons/medication-icon.svg';
import { ReactComponent as MedicationsIcon } from './icons/medications-icon.svg';
import { ReactComponent as MessageIcon } from './icons/message-icon.svg';
import { ReactComponent as NeedleIcon } from './icons/needle.svg';
import { ReactComponent as NoInternetIcon } from './icons/no-internet-icon.svg';
import { ReactComponent as O2Icon } from './icons/o2-icon.svg';
import { ReactComponent as OfficeIcon } from './icons/office.svg';
import { ReactComponent as OtherIcon } from './icons/other.svg';
import { ReactComponent as PencilIcon } from './icons/pencil-icon.svg';
import { ReactComponent as PhotoCameraIcon } from './icons/photo-camera-icon.svg';
import { ReactComponent as PhotoLibraryIcon } from './icons/photo-library-icon.svg';
import { ReactComponent as PillIcon } from './icons/pill-icon.svg';
import { ReactComponent as PlaceHolderIcon } from './icons/placeholder.svg';
import { ReactComponent as PrescriptionsIcon } from './icons/prescription.svg';
import { ReactComponent as IcProvider } from './icons/provider.svg';
import { ReactComponent as PulseIcon } from './icons/pulse-icon.svg';
import { ReactComponent as RadiologyIcon } from './icons/radiology-icon.svg';
import { ReactComponent as ReplayIcon } from './icons/replay-icon.svg';
import { ReactComponent as ReplyIcon } from './icons/reply-icon.svg';
import { ReactComponent as RespirationRateIcon } from './icons/respiration-rate-icon.svg';
import { ReactComponent as SchedulingIcon } from './icons/scheduling.svg';
import { ReactComponent as SentIcon } from './icons/sent-icon.svg';
import { ReactComponent as SurgeryIcon } from './icons/surgery-icon.svg';
import { ReactComponent as TemperatureIcon } from './icons/temperature-icon.svg';
import { ReactComponent as ThreeDotsIcon } from './icons/three-dots-icon.svg';
import { ReactComponent as TrashIcon } from './icons/trash.svg';
import { ReactComponent as UserIcon } from './icons/user-icon.svg';
import { ReactComponent as VideoIcon } from './icons/video.svg';
import { ReactComponent as VMDLogo } from './icons/vmd-logo.svg';
import { ReactComponent as WalgreensIcon } from './icons/walgreens.svg';
import { ReactComponent as WarningRoundIcon } from './icons/warning-round-icon.svg';
import { ReactComponent as WeightIcon } from './icons/weight-icon.svg';

const iconComponents = {
    attachment: AttachmentIcon,
    back: BackIcon,
    'band-aid': BandAidIcon,
    'blood-pressure': BloodPressureIcon,
    bmi: BmiIcon,
    building: BuildingIcon,
    'call-us': CallUsIcon,
    'chevron-down': ChevronDownIcon,
    'chevron-right': ChevronRightIcon,
    'chevron-up': ChevronUpIcon,
    close: CloseIcon,
    'cloud-question': CloudQuestion,
    context: ContextIcon,
    danger: DangerIcon,
    'default-provider': DefaultProvider,
    directions: DirectionsIcon,
    email: EmailIcon,
    file: FileIcon,
    'file-colored': FileColoredIcon,
    folder: FolderOpenIcon,
    height: HeightIcon,
    help: HelpIcon,
    'house-arrow': HouseArrowOut,
    icProvider: IcProvider,
    inbox: InboxIcon,
    lab: LabIcon,
    'lab-result': LabResultIcon,
    labs: LabsIcon,
    'medical-question': MedicalQuestionIcon,
    medication: MedicationIcon,
    medications: MedicationsIcon,
    message: MessageIcon,
    needle: NeedleIcon,
    'no-internet': NoInternetIcon,
    o2: O2Icon,
    office: OfficeIcon,
    other: OtherIcon,
    pencil: PencilIcon,
    'photo-camera': PhotoCameraIcon,
    'photo-library': PhotoLibraryIcon,
    pill: PillIcon,
    placeholder: PlaceHolderIcon,
    prescriptions: PrescriptionsIcon,
    pulse: PulseIcon,
    radiology: RadiologyIcon,
    replay: ReplayIcon,
    reply: ReplyIcon,
    'respiration-rate': RespirationRateIcon,
    scheduling: SchedulingIcon,
    sent: SentIcon,
    surgery: SurgeryIcon,
    temperature: TemperatureIcon,
    'test-results': TestResults,
    'three-dots': ThreeDotsIcon,
    trash: TrashIcon,
    user: UserIcon,
    video: VideoIcon,
    'vmd-logo': VMDLogo,
    walgreens: WalgreensIcon,
    warning: WarningRoundIcon,
    weight: WeightIcon,
};

type IconName = keyof typeof iconComponents;
type IconSizeRem = 0.5 | 0.75 | 1 | 1.5 | 1.25 | 1.75 | 2 | 2.5 | 3 | 3.5 | 4;

interface IconProps {
    readonly className?: string;
    readonly name: IconName;
    readonly size?: IconSizeRem;
    readonly width?: IconSizeRem;
    readonly height?: IconSizeRem;
    readonly onClick?: MouseEventHandler<SVGElement>;
}

const Icon: FC<IconProps> = memo(({ name, size, height, width, className, onClick }) => {
    const heightSpacing = height ?? size;
    const widthSpacing = width ?? size;

    const iconProps: SVGProps<SVGSVGElement> = {
        className,
        height: heightSpacing ? `${heightSpacing}rem` : '100%',
        onClick,
        width: widthSpacing ? `${widthSpacing}rem` : '100%',
    };

    const Component = iconComponents[name];

    return <Component {...iconProps} />;
});

export type { IconName, IconSizeRem, IconProps };
export { Icon };
