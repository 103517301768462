import { Button, Text } from '@village/ui';
import { rem } from 'polished';
import styled from 'styled-components';

const Title = styled(Text)`
    color: ${({ theme }) => theme.vui.colors.neutral.gray90};
    margin-bottom: ${rem(16)};
`;

const Body = styled(Text)`
    margin-bottom: ${rem(32)};
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${rem(12)};
`;

const AttachmentButton = styled(Button)`
    padding: 0;
`;

const Label = styled.label`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${rem(8)};
    padding: ${rem(8)} ${rem(16)};
    width: 100%;
`;

const VisuallyHidden = styled.span`
    display: inline-block;
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
`;

export { Label, AttachmentButton, Title, Body, ButtonWrapper, VisuallyHidden };
