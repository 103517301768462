import { Button } from '@village/ui';
import { useState, useCallback } from 'react';
import type { FC } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import * as Styled from './styles';
import { AttachmentInfo } from 'components/attachment-info';
import { AttachmentModal } from 'components/attachment-modal';
import { Icon } from 'components/icon';
import { useNativeControls } from 'hooks/use-native-controls';

const AttachmentsContent: FC = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [files, setFiles] = useState<Record<File['name'], File>>({});
    const location = useLocation();
    const navigate = useNavigate();
    const failedAttachments = location.state as readonly File[];

    const openAttachmentModal = useCallback(() => setIsOpen(true), []);
    const closeAttachmentModal = useCallback(() => setIsOpen(false), []);

    const onFileUpload = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            if (!event.target.files) return;
            Array.from(event.target.files).forEach((file) => {
                setFiles((previousFiles) => {
                    if (Object.keys(previousFiles).length > 9) return previousFiles;
                    return {
                        ...previousFiles,
                        [file.name]: file,
                    };
                });
            });
            closeAttachmentModal();
        },
        [closeAttachmentModal]
    );

    const openFailedPreview = useCallback(
        (file: File) => {
            navigate('/attachment-preview', { state: { file, hideDeleteButton: true } });
        },
        [navigate]
    );

    const openPreview = useCallback(
        (file: File) => {
            navigate('/attachment-preview', { state: { file } });
        },
        [navigate]
    );

    const getOpenPreviewHandler = (file: File) => () => openPreview(file);
    const getFailedOpenPreviewHandler = (file: File) => () => openFailedPreview(file);

    useNativeControls({
        attachmentButtonFunction: openAttachmentModal,
        ipcEvents: ['showBackButton', 'showAttachmentButton'],
        source: 'health-records',
        title: `Attachments (${failedAttachments.length})`,
    });

    return (
        <Styled.Wrapper>
            {failedAttachments
                ? failedAttachments.map((file) => (
                      <AttachmentInfo
                          key={file.name}
                          error="Something went wrong"
                          file={file}
                          onTouch={getFailedOpenPreviewHandler(file)}
                      />
                  ))
                : null}
            {Object.keys(files).map((fileName) => {
                const file = files[fileName];
                return <AttachmentInfo key={fileName} file={file} onTouch={getOpenPreviewHandler(file)} />;
            })}
            <AttachmentModal onClose={closeAttachmentModal} onFileUpload={onFileUpload} open={isOpen} />

            <Styled.ButtonWrapper>
                <Button
                    fullWidth={true}
                    onClick={openAttachmentModal}
                    size="medium"
                    startIcon={<Icon name="attachment" size={1.5} />}
                    variant="secondary"
                >
                    Upload attachment
                </Button>
            </Styled.ButtonWrapper>
        </Styled.Wrapper>
    );
};

export { AttachmentsContent };
