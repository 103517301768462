/* eslint-disable functional/immutable-data */
import { CardHeader } from '@material-ui/core';
import { Button } from '@village/ui';
import type { FC } from 'react';
import { Fragment, useCallback, useMemo, useState, useEffect, useRef } from 'react';
import type { QueryObserverResult } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { MaxFileSize, MessageLengthLimit, MessageLengthWarning, debounceDelay, sendThrottleInterval } from './constants';
import * as Styled from './styles';
import { MessageEntry } from '../message-reply-entry';
import { AttachmentFailModal } from 'components/attachment-fail-modal';
import { AttachmentInfo } from 'components/attachment-info';
import { AttachmentModal } from 'components/attachment-modal';
import { Icon } from 'components/icon';
import { MessageTextArea } from 'components/message-text-area';
import type { ValidateHandle } from 'components/message-text-area';
import { ProviderImage } from 'components/provider-image';
import { useCreateNewMessage } from 'data/hooks/use-create-new-message-query';
import type { MessagePayload } from 'data/hooks/use-create-new-message-query';
import { useMessageThreadAttachment } from 'data/hooks/use-message-thread-attachment-mutation';
import type { SuccessReplyResponse } from 'data/hooks/use-message-thread-attachment-mutation';
import { useMessageThreadReply } from 'data/hooks/use-message-thread-mutation';
import { useFeatureFlags, useThrottle } from 'hooks';
import { useDebounce } from 'hooks/use-debounce';
import { useMessaging } from 'hooks/use-messaging';
import { useNativeControls } from 'hooks/use-native-controls';
import { useNavHeader } from 'hooks/use-nav';
import { Countly } from 'modules/countly';
import { sendToNative } from 'modules/ipc';
import type { NativePopupState } from 'modules/ipc/types';
import type { ApiResponse, MessageThread } from 'types';
import type { Provider } from 'types/provider';

interface MessageReplyProps {
    readonly messageThread: MessageThread;
    readonly messageThreadId: number;
    readonly patientProviders?: readonly Provider[];
    readonly refetchThread: () => Promise<QueryObserverResult<ApiResponse<MessageThread>>>;
}

const MessageReply: FC<MessageReplyProps> = ({ messageThread, messageThreadId, patientProviders, refetchThread }) => {
    const {
        messagingState: { messageText: replyText, attachments },
        setMessagingFields,
        resetMessagingState,
    } = useMessaging();
    const replyTextDebounced = useDebounce<string>(replyText, debounceDelay);
    const { associated_message_thread_id, messages, message_thread_id, source, subject } = messageThread;
    const { setNavHeaderState } = useNavHeader();
    const [isAttachmentModalOpen, setIsAttachmentModalOpen] = useState<boolean>(false);
    const [isFailAttachmentModalOpen, setIsFailAttachmentModalOpen] = useState<boolean>(false);
    const totalAttachments = Object.keys(attachments).length;
    const hasAttachments = totalAttachments > 0;
    const attachmentsDoneUploading = useRef<number>(0);
    const messageTextAreaRef = useRef<ValidateHandle>(null);

    const { hasFeature } = useFeatureFlags();
    const isAttachmentsEnabled = hasFeature('attachments');
    const navigate = useNavigate();

    const { mutateAsync: attachmentMutateAsync } = useMessageThreadAttachment({
        onUploadProgress: ({ bytes, loaded }) => {
            if (bytes === loaded) {
                attachmentsDoneUploading.current += 1;
                sendToNative('showPopup', 'messaging', {
                    buttonCancel: {
                        text: 'Cancel',
                        type: 'primary',
                    },
                    text: `${attachmentsDoneUploading.current}/${totalAttachments}`,
                    title: 'Uploading...',
                } as NativePopupState);
            }
        },
    });

    // eslint-disable-next-line functional/prefer-readonly-type
    const navigateToThreadOnErrorParams = useRef<{ failedAttachments: File[] }>({
        failedAttachments: [],
    });
    const navigateToThreadOnError = useCallback(() => {
        const { failedAttachments } = navigateToThreadOnErrorParams.current;
        resetMessagingState();
        navigate(`/message/${messageThreadId}/inbox/${source}`, { replace: true, state: failedAttachments });
    }, [messageThreadId, navigate, resetMessagingState, source]);

    const afterSuccessReply = useCallback(() => {
        resetMessagingState();
        sendToNative('showDiscardPopup', 'messaging', {
            value: false, // Ensure native does not show popup when a reply is successful!
        });
        sendToNative('replyMessageSent', 'messaging', {
            value: true,
        });
        if (hasFeature('messaging.backAfterReply')) {
            navigate(-1);
        }
    }, [hasFeature, navigate, resetMessagingState]);

    const openFailAttachmentModal = useCallback(() => {
        setIsFailAttachmentModalOpen(true);
    }, [setIsFailAttachmentModalOpen]);

    const handleSendAttachments = useCallback(async () => {
        attachmentsDoneUploading.current = 0;
        const attachmentsObjectList = Object.values(attachments);

        const promises = attachmentsObjectList.map(async (file) => {
            const formData = new FormData();
            formData.append('file', file);
            return attachmentMutateAsync({ formData, messageThreadId });
        });
        const submitAttachments = async (): Promise<readonly PromiseSettledResult<SuccessReplyResponse>[]> =>
            Promise.allSettled(promises);
        const results = await submitAttachments();
        const isSuccess = !results.some((r) => r.status === 'rejected');
        if (isSuccess) {
            afterSuccessReply();
        } else {
            const failedAttachments = attachmentsObjectList.filter((_, index) => results[index].status === 'rejected');

            navigateToThreadOnErrorParams.current.failedAttachments = failedAttachments;
            openFailAttachmentModal();
        }
    }, [afterSuccessReply, attachmentMutateAsync, attachments, messageThreadId, openFailAttachmentModal]);

    const handleSuccessReply = async (): Promise<void> => {
        await refetchThread();
        if (hasAttachments) {
            await handleSendAttachments();
        } else {
            afterSuccessReply();
        }
    };

    // eslint-disable-next-line unicorn/consistent-function-scoping
    const handleErrorReply = (): void => {
        sendToNative('replyMessageSent', 'messaging', {
            value: false,
        });
    };

    const createNewMessageMutation = useCreateNewMessage({
        onError: handleErrorReply,
        onSuccess: handleSuccessReply,
    });
    const replyMutation = useMessageThreadReply({
        messageThreadId: associated_message_thread_id ?? messageThreadId,
        onError: handleErrorReply,
        onSuccess: handleSuccessReply,
    });

    const setMessage = useCallback(
        (newMessageText) => {
            setMessagingFields({
                messageText: newMessageText,
            });
        },
        [setMessagingFields]
    );

    const onChangeMessage = useCallback(
        (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
            setMessage(event.target.value);
        },
        [setMessage]
    );

    useEffect(() => {
        Countly.addEvent({
            key: 'replyMessage', // Track user clicking reply button
            segmentation: {
                messageThreadId,
                messagingVersion: 2,
                source: 'messaging',
            },
        });
    }, [messageThreadId]);

    const cancelButtonFunction = useCallback(() => {
        sendToNative('showPopup', 'messaging', {
            buttonCancel: {
                text: 'Cancel',
                type: 'primary',
            },
            buttonOK: {
                action: 'discardMessage',
                text: 'Discard',
                type: 'secondary',
            },
            text: 'Your message will not be saved',
            title: 'Discard message?',
        } as NativePopupState);
    }, []);

    useEffect(() => {
        const isValidInput = replyTextDebounced !== '';
        sendToNative('showDiscardPopup', 'messaging', {
            value: isValidInput,
        });
        setNavHeaderState((navHeaderState) => ({
            ...navHeaderState,
            backButton: !isValidInput,
            closeButton: isValidInput ? cancelButtonFunction : false,
        }));
    }, [cancelButtonFunction, replyTextDebounced, setNavHeaderState]);

    const handleSendReply = useCallback(() => {
        replyMutation.mutate(replyText.trim());
    }, [replyMutation, replyText]);

    const providerObject = useMemo(() => {
        const rootMessageIndex = messages.length - 1;
        const rootMessage = messages[rootMessageIndex];
        const provider = rootMessage.to?.providerid ? rootMessage.to : rootMessage.from;
        const liveProviderObject = patientProviders?.find((element) => element.providerid === provider?.providerid);
        return liveProviderObject;
    }, [messages, patientProviders]);

    const handleComposeLabMessage = useCallback(() => {
        const payload = {
            associated_lab_result_id: message_thread_id,
            department_id: providerObject?.department_ids && providerObject.department_ids[0],
            message_type: 'PATIENTCASE_LABS',
            provider_id: providerObject?.providerid,
            subject: `Clinical Question - Lab Result ${message_thread_id}`,
            text: replyText,
        } as MessagePayload;

        createNewMessageMutation.mutate(payload);
    }, [message_thread_id, providerObject?.department_ids, providerObject?.providerid, replyText, createNewMessageMutation]);

    const handleComposeImagingResultMessage = useCallback(() => {
        const payload = {
            associated_imaging_result_id: message_thread_id,
            department_id: providerObject?.department_ids && providerObject.department_ids[0],
            message_type: 'PATIENTCASE_IMAGING_RESULTS',
            provider_id: providerObject?.providerid,
            subject: `Clinical Question - Imaging Result ${message_thread_id}`,
            text: replyText,
        } as MessagePayload;

        createNewMessageMutation.mutate(payload);
    }, [message_thread_id, providerObject?.department_ids, providerObject?.providerid, replyText, createNewMessageMutation]);

    const handleSendMessage = useCallback(async () => {
        if (replyMutation.isLoading || createNewMessageMutation.isLoading) return;
        if (!messageTextAreaRef.current?.validate()) return;

        if (associated_message_thread_id || source === 'provider') {
            handleSendReply();
        } else if (source === 'labresult') {
            handleComposeLabMessage();
        } else if (source === 'imagingresult') {
            handleComposeImagingResultMessage();
        }
    }, [
        replyMutation.isLoading,
        createNewMessageMutation.isLoading,
        associated_message_thread_id,
        source,
        handleSendReply,
        handleComposeLabMessage,
        handleComposeImagingResultMessage,
    ]);

    const throttledHandleSendMessage = useThrottle(handleSendMessage, sendThrottleInterval);

    const messageUserAvatar = useMemo(
        () =>
            providerObject?.image?.url ? (
                <ProviderImage $size={2.5} alt={providerObject?.displayname ?? ''} src={providerObject.image.url} />
            ) : (
                <Styled.DefaultProvider name="icProvider" size={2.5} />
            ),
        [providerObject?.displayname, providerObject?.image?.url]
    );

    useNativeControls({
        ipcEvents: ['showBackButton', 'showSendButton'],
        sendButtonFunction: throttledHandleSendMessage,
        source: 'messaging',
        title: 'Reply',
    });

    useEffect(() => {
        document.addEventListener('sendReplyMessage', throttledHandleSendMessage);
        document.addEventListener('sendNewMessage', throttledHandleSendMessage);
        return () => {
            document.removeEventListener('sendReplyMessage', throttledHandleSendMessage);
            document.removeEventListener('sendNewMessage', throttledHandleSendMessage);
        };
    }, [throttledHandleSendMessage]);

    const messageThreadList = useMemo(
        () => messages.map((message) => <MessageEntry key={message.timestamp} message={message} />),
        [messages]
    );

    const closeAttachmentModal = useCallback(() => {
        setIsAttachmentModalOpen(false);
    }, [setIsAttachmentModalOpen]);

    const openAttachmentModal = useCallback(() => {
        setIsAttachmentModalOpen(true);
    }, [setIsAttachmentModalOpen]);

    const onFileUpload = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            if (!event.target.files) return;
            const originalAttachmentList = Object.entries(attachments);
            const fileList = [...event.target.files].map((file) => [file.name, file] as readonly [string, File]);
            const newList = [...originalAttachmentList, ...fileList];
            if (Object.keys(newList).length > 10) {
                sendToNative('showToast', 'messaging', { text: 'Only 10 attachments allowed' });
            }
            const newAttachments = Object.fromEntries(newList.slice(0, 10)) as Record<File['name'], File>;
            setMessagingFields({ attachments: newAttachments });
            closeAttachmentModal();
        },
        [attachments, closeAttachmentModal, setMessagingFields]
    );

    const onFileDelete = useCallback(
        (fileName: string): void => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { [fileName]: value, ...newAttachmentsWithoutFile } = attachments;
            setMessagingFields({ attachments: newAttachmentsWithoutFile });
            sendToNative('showToast', 'messaging', { text: 'Attachment successfully deleted' });
        },
        [attachments, setMessagingFields]
    );

    const openPreview = useCallback(
        (file: File) => {
            navigate('/attachment-preview', { state: { file } });
        },
        [navigate]
    );

    const getDeleteHandler = (fileName: string) => () => onFileDelete(fileName);
    const getOpenPreviewHandler = (file: File) => () => openPreview(file);

    return (
        <Fragment>
            <Styled.Container>
                <Styled.ReplyCard>
                    <CardHeader
                        avatar={messageUserAvatar}
                        title={
                            <Fragment>
                                <Styled.Sender type="body2">To: {providerObject?.displayname ?? 'Provider'}</Styled.Sender>
                                <Styled.Subject type="body1">{subject}</Styled.Subject>
                            </Fragment>
                        }
                    />
                    <Styled.BorderCity>
                        <MessageTextArea
                            ref={messageTextAreaRef}
                            autoFocus={true}
                            data-testid="reply-input"
                            disabled={replyMutation.isLoading || createNewMessageMutation.isLoading}
                            maxRows={7}
                            message={replyText}
                            messageLengthLimit={MessageLengthLimit}
                            messageLengthWarning={MessageLengthWarning}
                            messagePlaceholder="Type your message"
                            minRows={7}
                            onChangeMessage={onChangeMessage}
                        />
                        <Styled.AttachmentContainer>
                            {Object.keys(attachments).map((fileName) => {
                                const file = attachments[fileName];
                                return (
                                    <AttachmentInfo
                                        key={fileName}
                                        error={file.size > MaxFileSize ? 'File size exceeds 6 MB' : undefined}
                                        file={file}
                                        onDelete={getDeleteHandler(fileName)}
                                        onTouch={getOpenPreviewHandler(file)}
                                    />
                                );
                            })}
                        </Styled.AttachmentContainer>
                    </Styled.BorderCity>
                </Styled.ReplyCard>
                <Styled.MessagesList>{messageThreadList}</Styled.MessagesList>
            </Styled.Container>
            {isAttachmentsEnabled ? (
                <Styled.ActionBar>
                    <Styled.AttachmentButton
                        aria-label="Attach file"
                        onClick={openAttachmentModal}
                        size="small"
                        variant="tertiary"
                    >
                        <Icon name="attachment" size={1.5} />
                    </Styled.AttachmentButton>
                    <Button
                        disabled={replyMutation.isLoading || createNewMessageMutation.isLoading}
                        onClick={throttledHandleSendMessage}
                        size="small"
                    >
                        Send
                    </Button>
                </Styled.ActionBar>
            ) : null}
            <AttachmentModal onClose={closeAttachmentModal} onFileUpload={onFileUpload} open={isAttachmentModalOpen} />
            <AttachmentFailModal
                onClose={afterSuccessReply}
                onShowFailedAttachments={navigateToThreadOnError}
                onSkip={afterSuccessReply}
                open={isFailAttachmentModalOpen}
            />
        </Fragment>
    );
};

export { MessageReply };
