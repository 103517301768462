import { Box, TextareaAutosize } from '@material-ui/core';
import { Button, Text } from '@village/ui';
import { rem } from 'polished';
import styled from 'styled-components';

import { Icon } from 'components/icon';

const Container = styled(Box)`
    display: flex;
    flex-direction: column;
    padding-bottom: ${rem(60)};
`;

const MessageInput = styled(TextareaAutosize)`
    width: 100%;
    border: none;
    padding: 1rem;
    color: ${({ theme }) => theme.color.brightGray};
    border-top: 1px solid ${({ theme }) => theme.color.athensGray};
    border-bottom: 1px solid ${({ theme }) => theme.color.athensGray};
    resize: none;
    &:focus {
        outline: none;
    }
    ::placeholder {
        color: ${({ theme }) => theme.color.shuttleGray};
    }
`;

const ReplyCard = styled.div`
    display: flex;
    flex-direction: column;
`;

const MessagesList = styled.div`
    padding: 0 1rem;
`;

const DefaultProvider = styled(Icon)`
    background-color: ${({ theme }) => theme.color.white};
`;

const EmptyMessageError = styled.div`
    padding: 0.5rem 1rem;
    display: flex;
`;

const EmptyMessageErrorText = styled.p`
    padding-left: 0.5rem;
    font-size: 0.8rem;
    line-height: 1rem;
    color: ${({ theme }) => theme.color.errorRed};
`;

const ErrorMessageIcon = styled(Icon)`
    color: ${({ theme }) => theme.color.errorRed};
`;

const Sender = styled(Text)`
    color: ${({ theme }) => theme.color.brightGray};
`;

const Subject = styled(Text)`
    color: ${({ theme }) => theme.color.brightGray};
`;

const BorderCity = styled.div`
    border-top: 1px solid ${({ theme }) => theme.color.athensGray};
    border-bottom: 1px solid ${({ theme }) => theme.color.athensGray};
`;

const ActionBar = styled.div`
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    padding: ${rem(12)} ${rem(16)};
    background-color: ${({ theme }) => theme.vui.colors.general.white};
    border-top: 1px solid ${({ theme }) => theme.vui.colors.neutral.gray5};
`;

const AttachmentButton = styled(Button)`
    width: ${rem(48)};
    min-width: ${rem(48)};
    padding: 0;
`;

const AttachmentContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${rem(16)};
    padding: 0 ${rem(16)} ${rem(16)};
`;

export {
    Container,
    BorderCity,
    DefaultProvider,
    EmptyMessageError,
    EmptyMessageErrorText,
    ErrorMessageIcon,
    MessagesList,
    ReplyCard,
    MessageInput,
    Sender,
    Subject,
    ActionBar,
    AttachmentButton,
    AttachmentContainer,
};
