import { IconButton } from '@material-ui/core';
import { useRef, useState, useEffect } from 'react';
import type { FC } from 'react';

import * as Styled from './styles';
import { Icon } from 'components/icon';

interface Props {
    readonly file: File;
    readonly onDelete?: () => void;
    readonly onContext?: () => void;
    readonly onTouch?: () => void;
    readonly warning?: string;
    readonly error?: string;
}

const getFileExtension = (fileName: string): string =>
    fileName.slice((Math.max(0, fileName.lastIndexOf('.')) || Number.POSITIVE_INFINITY) + 1).toLowerCase();

const getFormattedFileName = (fileName: string): string => {
    const name = fileName.slice(0, fileName.lastIndexOf('.'));
    const extension = getFileExtension(fileName);
    return `${name}.${extension}`;
};

const formattedFileSize = (size: number): string => {
    const exponent = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1000));
    return `${(size / 1000 ** exponent).toFixed(1)} ${['B', 'KB', 'MB', 'GB', 'TB'][exponent]}`;
};

export const AttachmentInfo: FC<Props> = ({ file, onDelete, onContext, onTouch, error, warning }) => {
    const fileInfoRef = useRef<HTMLDivElement>(null);
    const [isEllipsisActive, setIsEllipsisActive] = useState<boolean>(false);
    const [preview, setPreview] = useState<string>('');
    const isImage = file.type.startsWith('image/');

    useEffect(() => {
        const calculateEllipsisActive = (): void => {
            if (Number(fileInfoRef?.current?.offsetWidth) < Number(fileInfoRef?.current?.scrollWidth)) {
                setIsEllipsisActive(true);
            } else {
                setIsEllipsisActive(false);
            }
        };
        calculateEllipsisActive();
        window.addEventListener('resize', calculateEllipsisActive);
        return () => window.removeEventListener('resize', calculateEllipsisActive);
    }, []);

    useEffect(() => {
        // eslint-disable-next-line functional/no-let
        let objectUrl = '';
        if (isImage) {
            objectUrl = URL.createObjectURL(file);
            setPreview(objectUrl);
        }
        return () => URL.revokeObjectURL(objectUrl);
    }, [file, isImage]);

    const getCaption = (): JSX.Element => {
        if (error) {
            return (
                <Styled.FileCaption $error={true} type="caption">
                    {error}
                </Styled.FileCaption>
            );
        }
        if (warning) {
            return (
                <Styled.FileCaption $warning={true} type="caption">
                    {warning}
                </Styled.FileCaption>
            );
        }
        return <Styled.FileCaption type="caption">{formattedFileSize(file.size)}</Styled.FileCaption>;
    };

    return (
        <Styled.Wrapper>
            <Styled.IconWrapper onClick={onTouch}>
                {isImage ? <Styled.Image alt="" src={preview} /> : <Icon name="file" size={1.5} />}
            </Styled.IconWrapper>
            <Styled.FileInfoWrapper onClick={onTouch}>
                <Styled.FileName ref={fileInfoRef} $extension={isEllipsisActive ? getFileExtension(file.name) : ''}>
                    {getFormattedFileName(file.name)}
                </Styled.FileName>
                {getCaption()}
            </Styled.FileInfoWrapper>
            {onDelete ? (
                <Styled.ActionWrapper>
                    <IconButton onClick={onDelete} size="small">
                        <Icon name="close" size={1} />
                    </IconButton>
                </Styled.ActionWrapper>
            ) : null}
            {onContext ? (
                <Styled.ActionWrapper>
                    <IconButton onClick={onContext} size="small">
                        <Icon name="context" size={1} />
                    </IconButton>
                </Styled.ActionWrapper>
            ) : null}
        </Styled.Wrapper>
    );
};
